import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { firestore } from '../firebase_config';
import { useAuth } from '../hooks/useAuth';

export default function ContactScreen() {
  const [data, setData] = useState();
  const { lang } = useAuth();

  useEffect(() => {
    getDoc(doc(firestore, 'basic', 'contact')).then((snap) => {
      if (snap.exists()) {
        setData(snap.data());
      }
    });
  }, []);

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Contact Us" : "ہم سے رابطہ کریں"}
        </h2>
      </div>
      <section className='contact' id='contact'>
        <div className='row'>
          <div className='col'>
            <p className='t'>
              {isEnglish ? "Mobile No.:" : "موبائل نمبر:"}

            </p>
            {/* <p className='d'> +91 {data && data['number']}</p> */}
            <p className='d'><span dir='ltr'>
              {isEnglish ? "+91 9756501822" : "+۹۱ ۹۷۵۶۵۰۱۸۲۲"}
            </span></p>
            <p className='d'><span dir='ltr'>
              {isEnglish ? "+91 9639291544" : "+۹۱ ۹۶۳۹۲۹۱۵۴۴"}
            </span></p>
            <p className='d'><span dir='ltr'>
              {isEnglish ? "+91 9557266265" : "+۹۱ ۹۵۵۷۲۶۶۲۶۵"}
            </span></p>
            <p className='d'><span dir='ltr'>
              {isEnglish ? "+91 7078630991" : "+۹۱ ۷۰۷۸۶۳۰۹۹۱"}
            </span></p>

            <p className='t'>
              {isEnglish ? "Email Address:" : "ای میل اڈریس:"}
            </p>
            {/* <p className='d'>{data && data['email']}</p> */}
            <p className='d'>nadeemmarkazi@gmail.com</p>
            <p className='t'>
              {isEnglish ? "Address:" : "پتہ:"}
            </p>
            {/* <p className='d'>
              {data &&
                (lang === 'en' ? data['address_en'] : data['address_ur'])}
            </p> */}
            <p className='d'>
              {
                isEnglish ?
                  "Bilaspur Road, Shishgarh Dist Bareilly, Uttar Pradesh(243505) India" :
                  "بلاس پور روڈ، شیش گڑھ ضلع بریلی، اتر پردیش (۲۴۳۵۰۵) بھارت"
              }
            </p>
          </div>
          <div className='col'>
            <iframe
              title='Address'
              // src={data && data['link']}
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6996.832259481238!2d79.32123260262449!3d28.73699021736613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390a9d7c76edad93%3A0x4786157ebd56f497!2sMadarsa%20darul%20uloom%20gousiya%20makhdumiya%20barkatul%20hadees!5e0!3m2!1sen!2sin!4v1676720875434!5m2!1sen!2sin"
              width='600'
              height='400'
              style={{ border: '0' }}
              allowFullScreen
              loading='lazy'
              referrerPolicy='no-referrer-when-downgrade'
            />
          </div>
        </div>
      </section>
    </>
  );
}
