import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function BooksScreen() {

  const books = [
    {
      name_en: "Irshadat e auliya e kiram",
      name_ur: "ارشادات اولیاء",
      cover_link: require('../images/books/covers/irshadat-e-auliya-e-kiram.jpg'),
      book_link: require('../books/irshadat-e-auliya-e-kiram.pdf'),
    },
    {
      name_en: "ilzam-wa-afwah-ya-haqiqat",
      name_ur: "الزام افواہ یا حقیقت؟",
      cover_link: require('../images/books/covers/ilzam-wa-afwah-ya-haqiqat.jpg'),
      book_link: require('../books/ilzam-wa-afwah-ya-haqiqat.pdf'),
    },
    {
      name_en: "tazkarae-ulmau-mashaikh",
      name_ur: "تذکرہ علما و مشائخ",
      cover_link: require('../images/books/covers/tazkarae-ulmau-mashaikh.jpg'),
      book_link: require('../books/tazkarae-ulmau-mashaikh.pdf'),
    },
    {
      name_en: "jan-nisari-ho-to-aisi",
      name_ur: "جاں نثاری ہو تو ایسی",
      cover_link: require('../images/books/covers/jan-nisari-ho-to-aisi.jpg'),
      book_link: require('../books/jan-nisari-ho-to-aisi.pdf'),
    },
    {
      name_en: "nayimul-qawaid-farsi",
      name_ur: "نعیم القواعد فارسی",
      cover_link: require('../images/books/covers/nayimul-qawaid-farsi.jpg'),
      book_link: require('../books/nayimul-qawaid-farsi.pdf'),
    },
    {
      name_en: "shaista-e-jawabat",
      name_ur: "صحیح جوابات",
      cover_link: require('../images/books/covers/shaista-e-jawabat.jpg'),
      book_link: require('../books/shaista-e-jawabat.pdf'),
    },
    {
      name_en: "makul-jawabat",
      name_ur: "معقول جوابات",
      cover_link: require('../images/books/covers/makul-jawabat.jpg'),
      book_link: require('../books/makul-jawabat.pdf'),
    },
    {
      name_en: "farmudate-sahawa-e-kiram",
      name_ur: "فرمودات صحابہ کرام مع رسالہ اقوال التابعین",
      cover_link: require('../images/books/covers/farmudate-sahawa-e-kiram.jpg'),
      book_link: require('../books/farmudate-sahawa-e-kiram.pdf'),
    },
    {
      name_en: "fazil-e-barelvi-ka-ilma-e-maqam",
      name_ur: "فاضل بریلوی کا علمی مقام",
      cover_link: require('../images/books/covers/fazil-e-barelvi-ka-ilma-e-maqam.jpg'),
      book_link: require('../books/fazil-e-barelvi-ka-ilma-e-maqam.pdf'),
    },
    {
      name_en: "Miftah-ul-Arabiyyah 1",
      name_ur: "مفتاح العربیہ اول",
      cover_link: require('../images/books/covers/Miftah-ul-Arabiyyah-1.jpg'),
      book_link: require('../books/Miftah-ul-Arabiyyah-1.pdf'),
    },
    {
      name_en: "Miftah-ul-Arabiyyah 2",
      name_ur: "مفتاح العربیہ دوم",
      cover_link: require('../images/books/covers/Miftah-ul-Arabiyyah-2.jpg'),
      book_link: require('../books/Miftah-ul-Arabiyyah-2.pdf'),
    },
    {
      name_en: "Miftah-ul-Arabiyyah 3",
      name_ur: "مفتاح العربیہ سوم",
      cover_link: require('../images/books/covers/Miftah-ul-Arabiyyah-3.jpg'),
      book_link: require('../books/Miftah-ul-Arabiyyah-3.pdf'),
    },
    {
      name_en: "mulfojat-e-sufiya-e-kiram",
      name_ur: "ملفوظات صوفیائے کرام",
      cover_link: require('../images/books/covers/mulfojat-e-sufiya-e-kiram.jpg'),
      book_link: require('../books/mulfojat-e-sufiya-e-kiram.pdf'),
    },
  ];

  const { lang } = useAuth();

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Our Books" : "ہماری کتابیں"}
        </h2>
      </div>
      <section className='books'>
        <div className='grid'>
          {
            books.map(
              (book) => <div className='book'>
                <img
                  src={book.cover_link}
                  alt={isEnglish ? book.name_en : book.name_ur}
                />
                <p className='title'>{isEnglish ? book.name_en : book.name_ur}</p>
                <a href={book.book_link} download>
                  <i className='material-icons'>download</i>
                  {isEnglish ? "Download" : "ڈاؤن لوڈ"}
                </a>
              </div>
            )
          }
        </div>
      </section>
    </>
  );
}
