import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function AboutScreen() {

  const { lang } = useAuth();

  const isEnglish = lang === 'en';
  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {
            isEnglish ?
              "About Us" :
              "ہمارے بارے میں"
          }
        </h2>
      </div>
      <section className='about'>
        <h3>
          {
            isEnglish ?
              "Madrasa Aliya Nomaniya Gharib Nawaz was established on 4 May 1997" :
              "مدرسہ عالیہ نعمانیہ غریب نواز کا سنگ بنیاد۴مئی۱۹۹۷ء"
          }
        </h3>
        <p>
          <b>
            {
              isEnglish ?
                "Location: " :
                "محل وقوع: "
            }
          </b>
          {
            isEnglish ?
              "This institution is located in Jama Masjid (Quraishian), the ancient settlement of the town of Sheshgarh. When you leave the mosque and walk towards the north, you will see this institution on the right hand side after about a hundred steps." :
              "یہ ادارہ قصبہ شیش گڑھ کی قدیم آبادی محلہ جامع مسجد (قریشیان) میں واقع ہے۔جب مسجد سے نکل کر جب آپ اتر کی جانب چلیں گے تو تقریباً سو قدم بعد داہنے ہاتھ پر یہ ادارہ آپ کو نظر آئےگا۔"
          }
        </p>
        <h4>
          {
            isEnglish ?
              "Brief introduction" :
              "مختصر تعارف"
          }
        </h4>
        <p>
          {
            isEnglish ?
              "In this Madrasa, since the beginning, along with the primary education, there has been provision of memorization and recitation and systematic education. Later, when the construction of Madrasah Darul Uloom Ghousia Makhdoomiya Barakat Hadith was completed under the same society, the department of memorization and recitation and the department of systematic education were established. Al-Uloom Ghausiya Makhdoomiya Barakat al-Hadith has been transferred. Future it will be made special for female students. Inshallah." :
              "اس مدرسہ میں شروع ہی سے پرائمری کے ساتھ ساتھ حفظ و قرآت اور درس نٖظامی کی تعلیم کا انتظام رہا ہے۔بعدہ جب اسی سوسائٹی کے تحت مدرسہ دار العلوم غوثیہ مخدومیہ برکات الحدیث کی تعمیر مکمل ہوئی تو شعبۂ حفظ و قرآت اور شعبۂ درس نظامی دار العلوم غوثیہ مخدومیہ برکات الحدیث کی طرف منتقل  کر دیا گیا۔آئندہ اسے تعلیم طالبات کے لیے خاص کرنا ہے۔انشاء اللہ۔"
          }
        </p>
        <p>
          <b>
            {
              isEnglish ?
                "Note:- " :
                "نوٹ:- "
            }
          </b>
          {
            isEnglish ?
              "The said institution is sometimes remembered as (Alia Nomaniya), sometimes (Gharaib Nawaz) and sometimes (Dar Uloom Gharib Nawaz). This institution consists of two storied building, ten rooms and two shops." :
              "مذکورہ ادارہ کو کبھی( عالیہ نعمانیہ) کبھی (غریب نواز) اور کبھی (دار العلوم غریب نواز) کے نام یاد کیا جاتا ہے۔یہ ادارہ دو منزلہ عمارت،دس کمروں اور دو دکانوں پر مشتمل ہے۔"
          }
        </p>
        <br />
        <br />
        <br />
        <h3>
          {
            isEnglish ?
              "Madrasah Darul Uloom Gousiya Makhdoomiya Barakat Ul Hadees was established on 6 September, 2013" :
              "مدرسہ دار العلوم غوثیہ مخدومیہ برکات الحدیث کا سنگ بنیاد۶ستمبر۲۰۱۳ء"
          }
        </h3>
        <p>
          <b>بدست اقدس:</b>
          تاج الاولیاء حضرت علامہ ڈاکٹر سید محمد جلال الدین اشرفی جیلانی دامت برکاتہم العالیہ کچھوچھہ مقدسہ
        </p>
        <p>
          <b>
            {
              isEnglish ?
                "Location: " :
                "محل وقوع: "
            }
          </b>
          {
            isEnglish ?
              "This institution is located half a kilometer away from the town of Sheshgarh, on the north side of Bilaspur road, at the eastern border of Rampur district in Pachham. The total number of rooms at present is 24, apart from shops and kitchen, the rooms measure 15 x 20." :
              "یہ ادارہ قصبہ شیش گڑھ سے آدھا کلو میٹر دور اتر جانب بلاسپور روڈ پر پچھم میں ضلع رامپور کی مشرقی سر حد پر واقع ہے اس کے پاس میں رامپور کے دو گاوں منصور پور اور سسونا پڑتے ہیں،یہ ادارہ بھی دو منزلہ عمارت پر مشتمل ہے اس وقت کمروں کی کل تعداد ۲۴/ہے  جو دکانوں اور باورچی خانہ کے علاوہ ہیں،کمروں کی پیمائش۱۵x۲۰ ہے۔"
          }
        </p>
        <p>
          {
            isEnglish ?
              "Alhamdulillah! In this institution, there is a good system of education of memorization and recitation and teaching system. There is also a reasonable arrangement of food and drink for the students. (1444 AH 2023) The number of graduated students is 34/." :
              "الحمد للہ! اس ادارہ میں حفظ و قرآت اور درس نظامی کی تعلیم کا عمدہ نظام ہے طلبہ کے لیے خورد و نوش کا بھی معقول انتظام ہے۔جس میں ہر سال حفظ و قرآت سے طلبہ فارغ ہو کر دین و سنیت کی خدمت میں لگ جاتے ہیں۔امسال (۱۴۴۴ھ ۲۰۲۳ء) فارغ التحصیل طلبہ کی تعداد ۳۷/ ہے۔"
          }
        </p>
        <p>
          {
            isEnglish ?
              "This institution does not receive any financial support from the government, but its total expenditure system is supported by the philanthropists of Ahl as-Sunnat." :
              "ادارۂ ہذا کو گورنمنٹ سے کوئی مالی امداد حاصل نہیں بلکہ اس کے خرچ کا کل نظام اہل سنت کے مخیر حضرات کے تعاون سے چلتا ہے۔جو زکوۃ،صدقات ،خیرات اور عطیات سے ہمدردان اہل سنت مدد کرتے ہیں۔"
          }
        </p>
        <p>
          <b>
            {
              isEnglish ?
                "Note:- " :
                "نوٹ:- "
            }
          </b>
          {
            isEnglish ?
              "Madrasah Darul Uloom Ghousia Makhdoomiya Barakat Al Hadith is affiliated to Government Arabic-Persian Board, Lucknow. The annual examination is organized by Centras Institute." :
              "مدرسہ دار العلوم غوثیہ مخدومیہ برکات الحدیث کا گورنمنٹ کے عربی فارسی بورڈ لکھنئو سے الحاق ہے۔جس کا سالانہ امتحان سینٹراس ادارہ کو بنایا گیا ہے۔تنظیم المدارس مغربی اتر پردیش سے بھی اس کا الحاق ہے۔"
          }
        </p>
        <br />
        <br />
        <br />
        <h3>
          بانی و ناظم اعلیٰ کا تعارف حالات و خدمات کے حوالہ سے
        </h3>
        <p>
          <b>نام مع آبا و اجداد:</b>
          محمد توفیق احمد بن اقبال احمد بن عبد القادر بن محمد ہاشم عرف لعل خاں
        </p>
        <p>
          <b>والدہ ماجدہ:</b>
          حجن مہر النساء بنت سکینہ حجن
        </p>
        <p>
          <b>تاریخ پیدائش:</b>
          ۱۵/ صفر ۱۳۸۷ھ    مطابق ۱۶/ مئی ۱۹۶۷ء
        </p>
        <p>
          <b>تعلیمی لیاقت:</b>
          قاری و فاضل جامعہ نعیمیہ مراد آباد/منشی-مولوی،عالم،کامل،فاضل از عربی فارسی بورڈ لکھنئو
        </p>
        <p>
          <b>مشاغل:-</b>
          درس و تدریس،اہتمام مدارس،سرپرستی تنظیمات،تصنیف کتب،اشاعت کتب،خدمت تبلیغ و تقریر وغیرہ۔
        </p>
        <p><b>مناسب:-</b></p>
        <ul>
          <li>
            سابق صدر مدرس جامعہ اہل سنت اشرف العلوم،شیش گڑھ

          </li>
          <li>
            بانی و ناظم اعلیٰ مجلس اسلامی-شیش گڑھ

          </li>
          <li>
            بانی و ناظم اعلیٰ مدرسہ عالیہ نعمانیہ غریب نواز

          </li>
          <li>
            بانی و ناظم اعلیٰ مدرسہ دار العلوم غوثیہ مخدومیہ برکات الحدیث

          </li>
          <li>
            بانی و ناظم اعلیٰ انجمن اصلاح المسلمین جامع مسجد ،شیش گڑھ

          </li>
          <li>
            بانی و سرپرست تحریک یادگار اعلیٰ حضرت ٹرسٹ

          </li>
          <li>
            صدر تنظیم المدارس مغربی اتر پردیس وغیرہ۔

          </li>
        </ul>


        <p>
          <b>کارہائے تعمیر:-</b></p>
        <ul>
          <li>
            سمنانی مسجد (تعمیر قدیم)

          </li>

          <li>
            مدرسہ اشرف العلوم کا جزوی کام

          </li>
          <li>
            مدرسہ غریب نواز

          </li>
          <li>
            دار العلوم برکات الحدیث،

          </li>

          <li>

            اشرف البرکات مسجد کی بنیادیں۔

          </li>
        </ul>



        <h3>
          شرف و اجازت و خلافت
        </h3>

        <ul>
          <li>
            از پیکر زہد و اتقا حضرت علامہ مفتی سید شاہد علی میاںشیری نعیمی،بہیڑی بریلی شریف

          </li>
          <li>
            تاج الاولیاء حضرت علامہ مولانا ڈاکٹر سید محمد جلال الدین      اشرف اشرفی جیلانی کچھوچھہ مقدسہ

          </li>
          <li>
            حامی اہل سنت قاطع بدعت حضرت سید مشیر الدین میاں صاحب قبلہ مارہرہ شریف (وارد حال دہلی)

          </li>
        </ul>



        <br />
        <br />
        <br />
        <h3>
          {
            isEnglish ?
              "Teachers" :
              "اساتذۂ کرام"
          }

        </h3>
        <h4>
          {
            isEnglish ?
              "Primary teachers:-" :
              "اساتذۂ پرائمری:-"
          }

        </h4>
        <ul>
          <li>
            حضرت حافظ غلام رسول  صاحب (مرحوم)
          </li>
          <li>
            حضرت مولانا طیب صاحب فاضل منظر اسلام بریلی شریف</li>
          <li>
            حضرت مولانا صدر الزماں صاحب فاضل منظر اسلام بریلی شریف
          </li>
        </ul>

        <h4>
          اساتذۂ کرام (از اعدادیہ تا رابعہ):-
        </h4>
        <ul>
          <li>
            حضرت مولانا مقبول احمد مصباحی فاضل جامعہ اشرفیہ مبارکپور
          </li>
          <li>
            حضرت مولانا مفتی عبد الحلیم صاحب نوری فاضل جامعہ نعیمیہ ،مرادآباد
          </li>
          <li>
            حضرت مولانا شریف احمد اشرفی فاضل منظر اسلام بریلی شریف
          </li>
          <li>
            حضرت مولانا عبد الجلیل نوری صاحب فاضل منظر اسلام بریلی شریف
          </li>
          <li>
            (چند دن) حضرت مولانا مختار عالم صاحب مصباحی،بہار
          </li>
          <li>
            حضرت مولانا عبد الرحمن صاحب مصباحی،نیپال
          </li>
          <li>
            حضرت مولانا جمال صاحب سابق صدر جامعہ قدیریہ کسرول مراد آباد
          </li>
        </ul>
        <h4>
          {
            isEnglish ?
              "Professor of Tajweed and Qaraat:-" :
              "استاذۂ تجوید و قرآت:-"
          }
        </h4>
        <ul>
          <li>
            حضرت ومولانا قاری احمد جمال القادری صاحب قبلہ،گھوسی مبارکپور (سابق استاذ جامعہ نعیمیہ )
          </li>
          <li>
            حضرت حافظ و قاری شریف احمد (سابق مدرس مدرسہ بدر العلوم جسپور )(چند دن)
          </li>
        </ul>

        <p>
          <b>
            سال فراغت از جامعہ نعیمیہ :شعبان ۱۴۰۹ھ مارچ ۱۹۸۹ء
          </b></p>
        <h3>
          مطبوعہ تصنیفات و تالیفات
        </h3>
        <p>ان دونوں اداروں کے بانی و ناظم اعلیٰ (مینجر) حضرت علامہ مولانا مفتی محمد توفیق احمد نعیمی  صاحب قبلہ ہیں۔یہ ملک کی مشہور یونیورسٹی جامعہ نعیمیہ مرادآباد سے فارغ التحصیل ہیں۔ان کی فضیلت میں اچھے نمبروں سے فراغت ہوئی ہے۔یہ کثیرا لتصانیف مصنف ہیں ان کی بعض تصنیفات کے نام یہ ہیں۔
        </p>

        <h4>
          مطبوعہ کتب:-
        </h4>

        <p>
          دعوت اتحاد-(ایک اجمالی جائزہ)- نجدی صیاد اپنے جال میں-دس صحیح مسائل-معقول جوابات (حسنات و سئیات)- ذہانت امام اعظم-مناقب امام اعظم-تقویت الایمانی عبارات کا جائزہ-حضور نور ہیں-بد مذہبوں سے نکاح-ہمارے حضور کا غیر مسلموں کے ساتھ حسن سلوک-چار یار مصطفی کی جاں نثاری-فرمودات صحابہ مع اقوال التابعین-جاں نثاری ہو تو ایسی-مدار ایمان مع دشمنان خدا ؤ رسول-ارشادات اولیائے کرام-مفتاح العربیہ(3حصے)-داستان مظلوم صحابہ کی- عظمت کنزالایمان (اعلیٰ حضرت کے ترجمۂ قرآن کی خوبیاں)-تذکرہ علماؤ مشائخ (پہلی قسط) –رمضان المبارک اور ہم-شائستہ جوابات-فاضل بریلوی کا علمی مقام- نعیم القواعد فارسی- ہمارے صدر الافاضل- الزام و افواہ یا حقیقت؟
        </p>

        <h4>
          رسائل میں مشمولہ کتب:

        </h4>

        <p>
          اشاعت اہل سنت ،چند تجاویز-اعلیٰ حضرت پر کتابیں-ماہنامہ اعلیٰ حضرت کا سنی رسائل میں منفرد مقام
        </p>

        <h4>
          غیر مطبوعہ کتب:-
        </h4>
        <p>
          اخلاق محمدی-حقوق مصطفیٰ-میزان و منشعب(جدید)-منہاج التعلیل (عربی قواعد و طریقۂ تعلیل میں)-حالات نجد-فتنوں کی سر زمین نجد پر ایک نظر-وغیرہ
        </p>
        <p>
          <b>نوٹ:-</b>
          ادارہ ہذا کی سرپرستی میں ایک تحریک بنام "تحریک یادگارِ اعلیٰ حضرت ایجوکیشنل اینڈ ویلفئر سوسائٹی بھی چل رہی ہے۔جس کا بنیادی کام ضرورت مندوں کی ضرورت پوری کرنا ہے ۔علاج  کے لیے،خوراک کے لیے،شادی بیاہ کے لیے ،تعلیم کے لیے،اور کئی ہنگامی معاملہ کے لیے یہ ادارہ ہر وقت تیار رہتا ہے۔یہ ادارہ ہر سال جاڑے کے موسم میں غریبوں کو کمبل تقسیم کرتا ہے،کسی غریب کا ایکسیڈنٹ ہو جائے تو اس کے علاج کے لیے پیسے دیتا ہے۔کوئی بندۂ خدا بھکمری کا شکار ہوتا ہے تو یہ ادارہ فوراً اس کی ضرورت کو پورا کرتا ہے،کوئی غریب بچہ دینی تعلیم جاری رکھنا چاہے تو اس کی مدد کو یہ ادارہ آگے بڑھتا ہے اور اس طرح کے بہت سارے رفاہی کام انجام دیتا ہے۔
        </p>
        <p>
          <b>ضروری نوٹ:-</b>
          چونکہ ہمارا پہلا ادارہ مدرسہ عالیہ نعمانیہ غریب نواز ہےاس لیےسوسائٹی رجسٹریشن اور بینک اکاونٹ اسی کے نام سے ہے۔
        </p>
        <br /><br /><br />
        <h3>
          {
            isEnglish ?
              "Islamic Majlis" :
              "مجلس اسلامی"
          }
        </h3>
        <p>
          {
            isEnglish ?
              "An organization (Majlis-e-Islami) is also running under this organization whose task is to write and publish academic, religious and literary books. So far, many books have been written and published under this organization." :
              "اس ادارے کے تحت ایک تنظیم (مجلس اسلامی) بھی چل رہی ہے جس کا کام علمی،دینی اور ادبی کتابیں لکھنا اور ان کا شائع کرنا ہے۔اب تک اس تنظیم کے تحت کثیر کتابیں لکھی اور شائع کی جا چکی ہیں۔"
          }
        </p>
        <br /><br /><br />
        <h3>
          {
            isEnglish ?
              "Our effort" :
              "ہماری کوشش"
          }
        </h3>
        <p>
          ہماری کوشش ہمیشہ یہ رہی ہے کہ دین و سنیت کا کام نہایت مضبوطی کے ساتھ کیا جائے مثلاً:
        </p>
        <ol>
          <li>
            جو حضرات دین سے دور ہیں انہیں دین سے قریب کیا جائے اور انہیں روزہ نماز کا پابند بنایا جائے اس کے لیے ہم تبلیغی دورے بھی کرتے ہیں۔
          </li>
          <li>
            ہماری کوشش رہتی ہے کہ علاقہ بھر میں جہاں کہیں کسی امام یا مدرس کی ضرورت ہو تو اسے پورا کیا جائے۔
          </li>
          <li>
            اہل سنت و جماعت میں کسی معاملہ کو لے کر کہیں انتشار و اختلاف ہوتا ہے اور ہمیں اس کی بھنک لگتی ہے تو علی الفور ہم اس اختلاف کو دور کرنے کی کوشش کرتے ہیں۔
          </li>
          <li>
            کوئی پریشان حال بیمار ،غریب ہمیں اپنی ضرورت میں یاد کرتا ہے ہماری کوشش رہتی ہے کہ اس کی ضرورت کو پہلی فرصت میں  پورا کیا جائے۔
          </li>
        </ol>

        <h4>
          {
            isEnglish ?
              "About Special Students:-" :
              "خاص طلبہ کے بارے میں:-"
          }
        </h4>
        <ul>
          <li>
            انہیں درس  و تدریس کے لیے قابل و صلاحیت مند بنایا جائے۔
          </li>
          <li>
            خطابت میں انہیں دور حاضر کے مطابق آراستہ کیا جائے۔
          </li>
          <li>
            انہیں قرآت  اور نماز وغیرہ کے ضروری مسائل سے اس قدر آراستہ کر دیا جائے کہ وہ فریضۂ امامت بخوبی انجام دے سکیں۔
          </li>
          <li>
            تنظیمی امور میں انہیں اس قدر باصلاحیت تجربہ کار بنا دیا جائے کہ وہ چاہیں تو کوئی ادارہ یا تنظیم بحسن و خوبی چلا سکیں۔
          </li>
          <li>
            اردو،ہندی اور عربی ادب سے ان کو حتی المقدور روشناس کرایا جائے۔
          </li>
          <li>
            کمپوٹر سائنس(اُردو،عربی اور انگلش کمپوزنگ،گرافک ڈزائنگ،ویب سائٹ ڈزائنگ اور بلاگ مینجر) جیسے عصری کاموں سے انہیں جوڑا جائے۔
          </li>
          <li>
            تبلیغ کے آداب و معمولات اور اس کی مشکلات سے اچھی طرح واقف کرا دیا جائے تاکہ وہ اس بابرکت خدمت کی خوب خوب ذمہ داری نبھا سکیں۔
          </li>
          <li>
            تحریر میں ان کو اس قدر باصلاحیت و باذوق بنا دیا جائے کہ وہ مضمون نگاری،مقالہ نگاری اور تصنیفات و تالیفات کے ذریعہ اسلام و سنیت کی خدمت انجام دے سکیں۔
          </li>
        </ul>
      </section>
    </>
  );
}
