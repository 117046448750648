import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { firestore } from '../firebase_config';
import { displayDate } from '../utils/utils';

export default function NewsScreen() {
  const [data, setData] = useState([]);
  const { lang } = useAuth();

  useEffect(() => {
    getDocs(query(collection(firestore, 'news'), orderBy('date', 'desc'))).then(
      (snap) => {
        let docs = [];
        snap.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        setData(docs);
      }
    );
  }, []);

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Our Latest News" : "ہماری تازہ ترین خبریں"}
        </h2>
      </div>
      <section className='news'>
        <ul>
          {data &&
            data.map((doc) => (
              <li key={doc.id}>
                <div className='dates'>
                  {doc['date'] && displayDate(doc['date'].toDate(), lang)}
                </div>
                <a
                  href={doc['link'] || ''}
                  target='_blank'
                  rel='noreferrer'
                  download
                >
                  {isEnglish ? doc['title_en'] : doc['title_ur']}
                </a>
              </li>
            ))}
        </ul>
      </section>
    </>
  );
}
