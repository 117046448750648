import { doc, getDoc, setDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react';
import { firestore } from '../../firebase_config';

export default function Contact() {
  const [saved, setSaved] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const numberRef = useRef();
  const emailRef = useRef();
  const addressEnRef = useRef();
  const addressUrRef = useRef();
  const linkRef = useRef();

  useEffect(() => {
    getDoc(doc(firestore, 'basic', 'contact')).then((snap) => {
      if (snap.exists()) {
        numberRef.current.value = snap.data()['number'];
        emailRef.current.value = snap.data()['email'];
        addressEnRef.current.value = snap.data()['address_en'];
        addressUrRef.current.value = snap.data()['address_ur'];
        linkRef.current.value = snap.data()['link'];
      }
    });
  }, []);

  function saveContact(e) {
    e.preventDefault();
    setError('');
    setSaved('');
    setLoading(true);

    setDoc(doc(firestore, 'basic', 'contact'), {
      number: numberRef.current.value,
      email: emailRef.current.value,
      address_en: addressEnRef.current.value,
      address_ur: addressUrRef.current.value,
      link: linkRef.current.value,
    })
      .then(() => {
        setSaved('Contact details saved successfully');
        setError('');
      })
      .catch(() => {
        setError('Something went wrong');
        setSaved('');
      });

    setLoading(false);
  }

  return (
    <>
      <form onSubmit={saveContact}>
        {error && (
          <div className='error'>
            <i className='material-icons'>error_outline</i>
            {error}
          </div>
        )}
        {saved && (
          <div className='saved'>
            <i className='material-icons'>check</i>
            {saved}
          </div>
        )}
        <label>
          <p>Mobile Number</p>
          <input
            type='number'
            placeholder='Enter 10 digit mobile number'
            ref={numberRef}
            required
          />
        </label>
        <label>
          <p>Email Address</p>
          <input
            type='email'
            placeholder='Enter email address'
            ref={emailRef}
            required
          />
        </label>
        <label>
          <p>Address in English</p>
          <input
            type='text'
            placeholder='Enter full address'
            ref={addressEnRef}
            required
          />
        </label>
        <label>
          <p>Address in Urdu</p>
          <input
            type='text'
            placeholder='Enter full address'
            ref={addressUrRef}
            required
          />
        </label>
        <label>
          <p>Address Link</p>
          <input
            type='url'
            placeholder='Enter google map address link'
            ref={linkRef}
            required
          />
        </label>
        <input type='submit' value='Save' disabled={loading} />
      </form>
    </>
  );
}
