import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { firestore } from '../firebase_config';
import { displayDate } from '../utils/utils';

export default function AdmissionScreen() {
  const [data, setData] = useState([]);
  const { lang } = useAuth();

  useEffect(() => {
    getDocs(
      query(collection(firestore, 'admission'), orderBy('date', 'desc'))
    ).then((snap) => {
      let docs = [];
      snap.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setData(docs);
    });
  }, []);

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Admission Announcement" : "داخلہ کا اعلان"}
        </h2>
      </div>
      <section className='admission'>
        <ul>
          {data &&
            data.map((doc) => (
              <li key={doc.id}>
                <div className='dates'>
                  {doc['date'] && displayDate(doc['date'].toDate(), lang)}
                </div>
                <a
                  href={doc['link'] || ''}
                  target='_blank'
                  rel='noreferrer'
                  download
                >
                  {lang === 'en' ? doc['title_en'] : doc['title_ur']}
                </a>
              </li>
            ))}
        </ul>
      </section>
    </>
  );
}
