import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import About from './about';
import Admission from './admission';
import Books from './books';
import Contact from './contact';
import Donate from './donate';
import Home from './home';
import News from './news';
import Result from './result';
import Syllabus from './syllabus';

export default function AdminScreen() {
  const { lang } = useAuth();

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? 'Admin' : "ایڈمن"}
        </h2>
      </div>

      <ul className='sub-nav'>
        <li>
          <NavLink
            to='./'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Home" : "گھر"}

          </NavLink>
        </li>
        <li>
          <NavLink
            to='about'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "About" : "بارے میں"}

          </NavLink>
        </li>
        <li>
          <NavLink
            to='admission'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Admission" : "داخلہ"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='syllabus'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Syllabus" : "نصاب"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='result'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Results" : "نتائج"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='news'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "News" : "خبریں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='books'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Books" : "کتابیں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='contact'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Contact" : "رابطہ کریں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='donate'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
          >
            {isEnglish ? "Donate" : "عطیہ کریں"}
          </NavLink>
        </li>
      </ul>
      <section className='admin'>
        <Routes>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='admission/*' element={<Admission />} />
          <Route path='syllabus/*' element={<Syllabus />} />
          <Route path='books' element={<Books />} />
          <Route path='contact' element={<Contact />} />
          <Route path='donate' element={<Donate />} />
          <Route path='news/*' element={<News />} />
          <Route path='result' element={<Result />} />
          <Route path='*' element={<h1>No page found</h1>} />
        </Routes>
      </section>
    </>
  );
}
