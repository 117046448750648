import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function Footer() {
  const { lang } = useAuth();

  const isEnglish = lang === 'en';

  return (
    <footer>

      <p>
        {isEnglish ? "Darul Uloom Ghausia Makhdoomiya Barkat Ul Hadees" : "دارالعلوم غوثیہ مخدومیہ برکات الحدیث"} &copy; {isEnglish ? "2023" : "۲۰۲۳"}
      </p>
    </footer>
  );
}
