import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';
import HomeScreen from './screens/home';
import AboutScreen from './screens/about';
import NewsScreen from './screens/news';
import ContactScreen from './screens/contact';
import ResultsScreen from './screens/results';
import BooksScreen from './screens/books';
import AdmissionScreen from './screens/admission';
import DonateScreen from './screens/donate';
import LoginScreen from './screens/login';
import AdminScreen from './screens/admin/admin';
import { useAuth } from './hooks/useAuth';
import SyllabusScreen from './screens/syllabus';
import GalleryScreen from './screens/gallery';

export default function App() {
  const { currentUser, lang } = useAuth();

  return (
    <BrowserRouter>
      <div dir={lang === 'en' ? 'ltr' : 'rtl'} className={lang === 'en' ? 'english' : 'urdu'}>
        <Header />
        <Routes>
          <Route index element={<HomeScreen />} />
          <Route path='about' element={<AboutScreen />} />
          <Route
            path='admin/*'
            element={currentUser ? <AdminScreen /> : <Navigate to='/login' />}
          />
          <Route path='admission' element={<AdmissionScreen />} />
          <Route path='syllabus' element={<SyllabusScreen />} />
          <Route path='books' element={<BooksScreen />} />
          <Route path='contact' element={<ContactScreen />} />
          <Route path='donate' element={<DonateScreen />} />
          <Route path='posters' element={<GalleryScreen />} />
          <Route path='login' element={<LoginScreen />} />
          <Route path='news' element={<NewsScreen />} />
          <Route path='results/*' element={<ResultsScreen />} />
          <Route path='logout' element={<Navigate to='/' />} />
          <Route path='*' element={<h1>No page found</h1>} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
