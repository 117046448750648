import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function LoginScreen() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, lang } = useAuth();

  const isEnglish = lang === 'en';

  function handleSubmit(e) {
    e.preventDefault();

    setError('');
    setLoading(true);

    login(emailRef.current.value, passwordRef.current.value)
      .then(() => {
        navigate('/admin');
      })
      .catch((e) => {
        setError('Failed to log in' + e);
      });

    setLoading(false);
  }

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? 'Login' : "لاگ ان"}
        </h2>;
      </div>
      <section className='login'>
        <form onSubmit={handleSubmit}>
          {error && (
            <div className='error'>
              <i className='material-icons'>error_outline</i>
              {error}
            </div>
          )}
          <label>
            <span>
              {isEnglish ? "Email:" : "ای میل:"}
            </span>
            <input
              type='email'
              name='email'
              autoComplete='username'
              ref={emailRef}
              required
            />
          </label>
          <label>
            <span>
              {isEnglish ? "Password:" : "پاس ورڈ:"}
            </span>
            <input
              type='password'
              name='password'
              autoComplete='current-password'
              ref={passwordRef}
              required
            />
          </label>
          <input
            type='submit'
            value={isEnglish ? 'Login' : "لاگ ان"}
            disabled={loading}
          />
        </form>
      </section>
    </>
  );
}
