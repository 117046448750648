import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, Route, Routes, useParams } from 'react-router-dom';
import { firestore, storage } from '../../firebase_config';
import { displayDate } from '../../utils/utils';
import { useAuth } from '../../hooks/useAuth';

export default function Admission() {
  return (
    <>
      <Routes>
        <Route index element={<AdmissionList />} />
        <Route path='add' element={<AddAdmission />}>
          <Route path=':id' element={<AddAdmission />} />
        </Route>
      </Routes>
    </>
  );
}

function AdmissionList() {
  const [data, setData] = useState([]);
  const { lang } = useAuth();

  useEffect(() => {
    getDocs(
      query(collection(firestore, 'admission'), orderBy('date', 'desc'))
    ).then((snap) => {
      let docs = [];
      snap.forEach((doc) => {
        docs.push({ ...doc.data(), id: doc.id });
      });
      setData(docs);
    });
  }, []);

  function deleteAdmission(id, fileRef) {
    deleteDoc(doc(firestore, 'admission', id)).then(() => {
      deleteObject(ref(storage, fileRef)).then(() => {
        window.location.reload();
      });
    });
  }

  return (
    <div className='admission-list'>
      <Link to='add' className='btn new'>
        <i className="material-icons">add</i>
        Add New Admission Announcement
      </Link>
      <ul>
        {data &&
          data.map((doc) => (
            <li key={doc.id}>
              <div className='leading'>
                {doc['date'] && displayDate(doc['date'].toDate(), lang)}
              </div>
              <div className='main'>
                <p>{doc['title_en']}</p>
                <p>{doc['title_ur']}</p>
                <a
                  href={doc['link'] || ''}
                  target='_blank'
                  rel='noreferrer'
                  download
                >
                  {doc['name']}
                </a>
              </div>
              <div className='trailing'>
                <Link to={'add/' + doc.id} className='btn'>
                  <i className='material-icons'>edit</i>Edit
                </Link>
                <span
                  onClick={() => deleteAdmission(doc.id, doc['ref'])}
                  className='btn'
                >
                  <i className='material-icons'>delete</i>Delete
                </span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
}

function AddAdmission() {
  const [error, setError] = useState('');
  const [oldFileName, setOldFileName] = useState('');
  const [oldFileRef, setOldFileRef] = useState('');
  const [oldFileLink, setOldFileLink] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  let { id } = useParams();

  const enTitleRef = useRef();
  const urTitleRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    id &&
      getDoc(doc(firestore, 'admission', id)).then((snap) => {
        if (snap.exists()) {
          enTitleRef.current.value = snap.data()['title_en'];
          urTitleRef.current.value = snap.data()['title_ur'];
          setOldFileName(snap.data()['name']);
          setOldFileRef(snap.data()['ref']);
          setOldFileLink(snap.data()['link']);
        }
      });
  }, [id]);

  function addAdmission(e) {
    e.preventDefault();
    setError('');
    setLoading(true);

    if (id) {
      if (change) {
        const fileName = file.size + '-' + file.name;
        const storageRef = ref(storage, 'admission/' + fileName);

        uploadBytes(storageRef, file).then((snap) => {
          getDownloadURL(snap.ref).then((url) => {
            updateDoc(doc(firestore, 'admission', id), {
              title_en: enTitleRef.current.value,
              title_ur: urTitleRef.current.value,
              link: url,
              ref: snap.ref.fullPath,
              name: file.name,
              date: serverTimestamp(),
            })
              .then(() => {
                deleteObject(ref(storage, oldFileRef));
                setError('');
                navigate('..');
              })
              .catch(() => {
                setError('Something went wrong');
              });
          });
        });
      } else {
        updateDoc(doc(firestore, 'admission', id), {
          title_en: enTitleRef.current.value,
          title_ur: urTitleRef.current.value,
          date: serverTimestamp(),
        })
          .then(() => {
            setError('');
            navigate('..');
          })
          .catch(() => {
            setError('Something went wrong');
          });
      }
    } else {
      const fileName = file.size + '-' + file.name;
      const storageRef = ref(storage, 'admission/' + fileName);

      uploadBytes(storageRef, file).then((snap) => {
        getDownloadURL(snap.ref).then((url) => {
          addDoc(collection(firestore, 'admission'), {
            title_en: enTitleRef.current.value,
            title_ur: urTitleRef.current.value,
            link: url,
            ref: snap.ref.fullPath,
            name: file.name,
            date: serverTimestamp(),
          })
            .then(() => {
              setError('');
              navigate('..');
            })
            .catch(() => {
              setError('Something went wrong');
            });
        });
      });
    }
    setLoading(false);
  }

  const fileChangeHandler = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      selectedFile = null;
    } else {
      setFile(null);
    }
  };

  return (
    <>
      <form onSubmit={addAdmission}>
        <h3 className='title'>
          {id ? 'Edit Admission Announcement' : 'Add Admission Announcement'}
        </h3>
        {error && (
          <div className='error'>
            <i className='material-icons'>error_outline</i>
            {error}
          </div>
        )}
        <label>
          <p>English Announcement Title</p>
          <input
            type='text'
            placeholder='Enter english announcement title'
            ref={enTitleRef}
            required
          />
        </label>
        <label>
          <p>Urdu Announcement Title</p>
          <input
            type='text'
            placeholder='Enter urdu announcement title'
            ref={urTitleRef}
            required
          />
        </label>
        {id && !change ? (
          <div>
            <a href={oldFileLink} target='_blank' rel='noreferrer' download>
              {oldFileName}
            </a>
            <button type='button' onClick={() => setChange(true)}>
              Change File
            </button>
          </div>
        ) : (
          <>
            <label>
              <p>Upload Announcement File (Image/PDF)</p>
              <input
                type='file'
                accept='image/*,application/pdf'
                onChange={fileChangeHandler}
                required
              />
            </label>
            {id && (
              <button type='button' onClick={() => setChange(false)}>
                Use Old
              </button>
            )}
          </>
        )}

        <input
          type='submit'
          value={
            id ? 'Edit Admission Announcement' : 'Add Admission Announcement'
          }
          disabled={loading}
        />
      </form>
    </>
  );
}
