import { React, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { displayDate } from '../utils/utils';
import { useAuth } from '../hooks/useAuth';

export default function Header() {
  const [icon, setIcon] = useState('menu');
  const { currentUser, logout, lang } = useAuth();
  const { pathname } = useLocation();

  function toggleMenu() {
    setIcon(icon === 'menu' ? 'close' : 'menu');
    document.getElementById('nav').classList.toggle('open');
  }

  function setLang(lang) {
    document.cookie = 'lang=' + lang + '; max-age=2592000; path=/';
    window.location.reload();
  }

  const isEnglish = lang === 'en';

  return (
    <header>
      <ul className='min-nav'>
        <li className='date'>{displayDate(new Date(), lang)}</li>
        <li className='language'>
          {isEnglish ? 'Language' : 'زبان'}:
        </li>
        <li>
          <span
            className={isEnglish ? 'lang selected' : 'lang'}
            onClick={() => setLang('en')}
          >
            English
          </span>
        </li>
        <li>
          <span
            className={isEnglish ? 'lang' : 'lang selected'}
            onClick={() => setLang('ur')}
          >
            اردو
          </span>
        </li>
        <li className='nav-login'>
          <NavLink
            to={
              currentUser != null
                ? pathname.match('/admin')
                  ? 'logout'
                  : 'admin'
                : 'login'
            }
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={pathname.match('/admin') && logout}
          >
            {currentUser != null
              ? pathname.match('/admin')
                ? isEnglish ? 'Logout' : "لاگ آوٹ"
                : isEnglish ? 'Admin' : "ایڈمن"
              : isEnglish ? 'Login' : "لاگ ان"
            }
          </NavLink>
        </li>
      </ul>
      <div dir='ltr' className='logos'>
        <div className='logo en english'>
          <h3>Madrasa Aliya Nomaniya Gareeb Nawaz</h3>
          <h1>Darul Uloom Gousiya Makhdoomiya</h1>
          <h2>Barkat Ul Hadees</h2>
        </div>
        <img src={require('../images/logo.jpg')} alt="Logo"
        />
        <div className='logo urdu'>
          <h3>مدرسہ عالیہ نعمانیہ غریب نواز</h3>
          <h1>دارالعلوم غوثیہ مخدومیہ</h1>
          <h2>برکات الحدیث</h2>
        </div>
      </div>
      <div className='menu' onClick={toggleMenu}>
        <i className='material-icons'>{icon}</i>
      </div>
      <ul className='main-nav' id='nav'>
        <li>
          <NavLink
            to='/'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Home" : "گھر"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/about'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "About" : "بارے میں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/posters'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Posters" : "پوسٹرز"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/admission'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Admission" : "داخلہ"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/syllabus'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Syllabus" : "نصاب"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/results'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Results" : "نتائج"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/news'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "News" : "خبریں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/books'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Books" : "کتابیں"}
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/contact'
            className={({ isActive }) => (isActive ? 'selected' : undefined)}
            onClick={toggleMenu}
          >
            {isEnglish ? "Contact" : "رابطہ کریں"}
          </NavLink>
        </li>
        <li className='button'>
          <NavLink to='/donate' onClick={toggleMenu}>
            {isEnglish ? "Donate" : "عطیہ کریں"}
          </NavLink>
        </li>
      </ul>
    </header>
  );
}
