import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function DonateScreen() {

  const { lang } = useAuth();

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Donate" : "عطیہ کریں"}
        </h2>
      </div>
      <section className='donate'>
        <img className='bank-logo' src={require('../images/bob_logo.png')} alt="Bank of Baroda Logo" />

        <p><b>A/C:</b> 06220100003447</p>
        <p><b>IFSC:</b> BARB0SHISHG</p>
        <p>Prabandhak Madrasa Aaliya Nomaniya Garib Nawaz</p>
      </section>
    </>
  );
}
