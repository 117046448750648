import React from 'react';
import { useAuth } from '../hooks/useAuth';

export default function GalleryScreen() {

    const notice = [
        { link: require('../images/Notice/12 Rabiul Awwal.jpg') },
        { link: require('../images/Notice/Qurbani.jpg') },
    ];
    const jalsa = [
        { link: require('../images/Jalsa/2024.jpg'), year: 2024 },
        { link: require('../images/Jalsa/2023.jpg'), year: 2023 },
        { link: require('../images/Jalsa/2019.jpg'), year: 2019 },
        { link: require('../images/Jalsa/2018.jpg'), year: 2018 },
        { link: require('../images/Jalsa/2017.jpg'), year: 2017 },
        { link: require('../images/Jalsa/2016.jpg'), year: 2016 },
        { link: require('../images/Jalsa/2014.jpg'), year: 2014 },
        { link: require('../images/Jalsa/2013.jpg'), year: 2013 },
        { link: require('../images/Jalsa/2008.jpg'), year: 2008 },
        { link: require('../images/Jalsa/2006.jpg'), year: 2006 },
        { link: require('../images/Jalsa/2005.jpg'), year: 2005 },
        { link: require('../images/Jalsa/2003.jpg'), year: 2003 },
        { link: require('../images/Jalsa/2002.jpg'), year: 2002 },
        { link: require('../images/Jalsa/2001.jpg'), year: 2001 },
    ];
    const ramadan = [
        { link: require('../images/Ramzan/2024.jpg'), year: 2024 },
        { link: require('../images/Ramzan/2023.jpg'), year: 2023 },
        { link: require('../images/Ramzan/2022.jpg'), year: 2022 },
        { link: require('../images/Ramzan/2021.jpg'), year: 2021 },
        { link: require('../images/Ramzan/2018.jpg'), year: 2018 },
        { link: require('../images/Ramzan/2016.jpg'), year: 2016 },
        { link: require('../images/Ramzan/2013.jpg'), year: 2013 },
        { link: require('../images/Ramzan/2011.jpg'), year: 2011 },
        { link: require('../images/Ramzan/2010.jpg'), year: 2010 },
        { link: require('../images/Ramzan/2009.jpg'), year: 2009 },
        { link: require('../images/Ramzan/2006.jpg'), year: 2006 },
        { link: require('../images/Ramzan/2005.jpg'), year: 2005 },
        { link: require('../images/Ramzan/2000.jpg'), year: 2000 },
        { link: require('../images/Ramzan/1998.jpg'), year: 1998 },
    ];

    const { lang } = useAuth();

    const isEnglish = lang === 'en';

    return (
        <>
            <div className='banner'>
                <h2 className='title'>
                    {isEnglish ? "Our Posters" : "ہمارے پوسٹرز"}
                </h2>
            </div>
            <section className='books'>
                <h3>{isEnglish ? 'Notice' : 'نوٹس'}</h3>
                <div className='grid'>
                    {
                        notice.map(
                            (book) => <div className='book'>
                                <img src={book.link} alt='' />
                            </div>
                        )
                    }
                </div>

                <h3>{isEnglish ? 'Jalsa' : 'جلسہ'}</h3>
                <div className='grid'>
                    {
                        jalsa.map(
                            (book) => <div className='book'>
                                <img src={book.link} alt='' />
                                <p className='title'>{book.year}</p>
                            </div>
                        )
                    }
                </div>

                <h3>{isEnglish ? 'Ramadan' : 'رمضان'}</h3>
                <div className='grid'>
                    {
                        ramadan.map(
                            (book) => <div className='book'>
                                <img src={book.link} alt='' />
                                <p className='title'>{book.year}</p>
                            </div>
                        )
                    }
                </div>
            </section>
        </>
    );
}
