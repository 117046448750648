export function displayDate(date, lang) {
    let month;
    let result;
    if (lang === 'en') {
        switch (date.getMonth()) {
            case 0:
                month = 'January';
                break;
            case 1:
                month = 'February';
                break;
            case 2:
                month = 'March';
                break;
            case 3:
                month = 'April';
                break;
            case 4:
                month = 'May';
                break;
            case 5:
                month = 'June';
                break;
            case 6:
                month = 'July';
                break;
            case 7:
                month = 'August';
                break;
            case 8:
                month = 'September';
                break;
            case 9:
                month = 'October';
                break;
            case 10:
                month = 'November';
                break;
            case 11:
                month = 'December';
                break;
            default: break;
        }

        result = date.getDate() + ' ' + month + ', ' + date.getFullYear();
    } else {
        switch (date.getMonth()) {
            case 0:
                month = 'جنوری';
                break;
            case 1:
                month = 'فروری';
                break;
            case 2:
                month = 'مارچ';
                break;
            case 3:
                month = 'اپریل';
                break;
            case 4:
                month = 'مئی';
                break;
            case 5:
                month = 'جون';
                break;
            case 6:
                month = 'جولائی';
                break;
            case 7:
                month = 'اگست';
                break;
            case 8:
                month = 'ستمبر';
                break;
            case 9:
                month = 'اکتوبر';
                break;
            case 10:
                month = 'نومبر';
                break;
            case 11:
                month = 'دسمبر';
                break;
            default: break;
        }
        result = date.getDate() + ' ' + month + ' ' + date.getFullYear();

        var id = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];
        result = result.replace(/[0-9]/g, (w) => id[+w]);
    }

    return result;
}