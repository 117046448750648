import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAlZNj0Ecz73OqcQrhu5z4Gxedp24kjBAw",
    authDomain: "darul-uloom-gm.firebaseapp.com",
    projectId: "darul-uloom-gm",
    storageBucket: "darul-uloom-gm.appspot.com",
    messagingSenderId: "843762579511",
    appId: "1:843762579511:web:3333043e879f8077a227b2"
};

const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, auth };