import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';

export default function ResultsScreen() {
  const { lang } = useAuth();

  const isEnglish = lang === 'en';

  return (
    <>
      <div className='banner'>
        <h2 className='title'>
          {isEnglish ? "Results" : "نتائج"}
        </h2>
      </div>
      <section className='results'>
        <Routes>
          <Route index element={<ResultsList />} />
          <Route path='/:result' element={<ResultForm />} />
          <Route path='/:result/:id' element={<Result />} />
          <Route path='*' element={<h1>No page found</h1>} />
        </Routes>
      </section>
    </>
  );
}

function Result() {
  return (
    <div className='result'>
      <table border='true' cellSpacing='0'>
        <thead>
          <tr>
            <th>
              <h1>Darul Uloom Ghausia Makhdoomiya</h1>
              <h2>Barkat Ul Hadees</h2>
            </th>
          </tr>
          <tr>
            <th>Class 5th Examination Result (2022)</th>
          </tr>
        </thead>
      </table>
      <table border='true' cellSpacing='0' className='personal'>
        <thead>
          <tr>
            <th colSpan='2'>Personal Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Roll No:</th>
            <td>17232</td>
          </tr>
          <tr>
            <th>Name:</th>
            <td>Momin Ahmad</td>
          </tr>
          <tr>
            <th>Fathers Name:</th>
            <td>Shamshad Hussain</td>
          </tr>
          <tr>
            <th>Date of Birth:</th>
            <td>12/05/2000</td>
          </tr>
          <tr>
            <th>Declared on:</th>
            <td>06/04/2022</td>
          </tr>
        </tbody>
      </table>
      <table border='true' cellSpacing='0'>
        <thead>
          <tr>
            <th colSpan='4'>Examination Details</th>
          </tr>
          <tr>
            <th>Subject</th>
            <th>Max Marks</th>
            <th>Obtained Marks</th>
            <th>Grade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Hindi</td>
            <td>100</td>
            <td>90</td>
            <td>B1</td>
          </tr>
          <tr>
            <td>English</td>
            <td>100</td>
            <td>83</td>
            <td>B2</td>
          </tr>
          <tr>
            <td>Mathematics</td>
            <td>100</td>
            <td>94</td>
            <td>A2</td>
          </tr>
          <tr>
            <td>Science</td>
            <td>100</td>
            <td>98</td>
            <td>A1</td>
          </tr>
          <tr>
            <td>Social Science</td>
            <td>100</td>
            <td>76</td>
            <td>C2</td>
          </tr>
          <tr>
            <td>Drawing</td>
            <td>100</td>
            <td>99</td>
            <td>A1</td>
          </tr>
        </tbody>
      </table>
      <table border='true' cellSpacing='0'>
        <thead>
          <tr>
            <th colSpan='4'>Fianl Result</th>
          </tr>
          <tr>
            <th>Max Total Marks</th>
            <th>Obtained Total Marks</th>
            <th>Percentage</th>
            <th>Result</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>600</td>
            <td>555</td>
            <td>92</td>
            <td>Passed</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function ResultsList() {
  return (
    <ul>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to='class-10th-2022'>
          Class 10th Result (2022)
          <i className='material-icons'>books</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>text</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>books</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>text</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>books</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>text</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>books</i>
        </Link>
      </li>
      <li>
        <div className='dates'>05/04/2022</div>
        <Link to=''>
          New adsmission will start from 10 may 2022
          <i className='material-icons'>text</i>
        </Link>
      </li>
    </ul>
  );
}

function ResultForm() {
  return (
    <div className='result-form'>
      <form>
        <label>
          <p>Roll Number</p>
          <input type='number' name='rollno' placeholder='Enter roll number' />
        </label>
        <label>
          <p>Date of Birth</p>
          <input type='date' name='dob' />
        </label>
        <input type='submit' value='Search' />
      </form>
    </div>
  );
}
