import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function HomeScreen() {
  return (
    <div dir='ltr'>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        emulateTouch={true}
        stopOnHover={false}
      >
        <div>
          <img src={require('../images/slides/slide1.jpg')} alt='Slide 1' />
        </div>
        <div>
          <img src={require('../images/slides/slide2.jpg')} alt='Slide 2' />
        </div>
        <div>
          <img src={require('../images/slides/slide3.jpg')} alt='Slide 3' />
        </div>
        <div>
          <img src={require('../images/slides/slide4.jpg')} alt='Slide 4' />
        </div>
      </Carousel>

      <section className='home'>
        <img className='ramadan-poster' src={require('../images/Ramzan/2024.jpg')} alt="Ramadan 2024" />
      </section>
    </div>
  );
}
